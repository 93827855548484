<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">Error: {{ error.message }}</div>
  <div v-else-if="repositories">
    <div class="export-csv">
      <button
        class="btn"
        @click="csvExport(formatRepositories)"
        @keypress="csvExport(formatRepositories)"
      >
        Export CSV
      </button>
    </div>
    <div class="table-responsive">
      <table class="table table-sm small">
        <thead v-if="hasRepositories" class="table-light">
          <tr>
            <th id="source" class="fit">SOURCE</th>
            <th id="repo_name" class="fit">REPOSITORY NAME</th>
            <th id="status" class="fit">URL</th>
            <th id="status" class="fit">Auth User</th>
            <th id="last_scanned" class="fit">LAST SCANNED</th>
          </tr>
        </thead>
        <tbody v-if="hasRepositories">
          <tr v-for="(repository, index) in repositories" :key="index">
            <td data-testid="repo-provider">
              {{ repository.provider || "--" }}
            </td>
            <td data-testid="repo-name">{{ repository.name || "--" }}</td>
            <td data-testid="repo-url">
              <a :href="repository.url" target="_blank">{{
                repository.url || "--"
              }}</a>
            </td>
            <td data-testid="repo-auth-user">
              {{ repository.authUser || "--" }}
            </td>
            <td data-testid="repo-last-scanned">
              {{ getUTCDateTime(repository.lastScanned) || "--" }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>No repositories</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <PaginationApi
    v-if="hasRepositories"
    :page-size="state.pageSize"
    :handle-page-size-change="handlePageSizeChange"
    :has-previous-page="hasPreviousPage"
    :has-next-page="hasNextPage"
    :load-more="loadMore"
    class="mb-5"
  />
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive, computed } from "vue";

import PaginationApi from "@/components/PaginationApi.vue";
import useLoadMore from "@/composables/useLoadMore";
import allRepositoriesQuery from "@/graphql/allRepositories.query.gql";
import { csvExport } from "@/utils/csvExport.js";
import { getUTCDateTime } from "@/utils/datetime.js";

export default {
  name: "RepositoriesList",
  components: { PaginationApi },
  setup() {
    const state = reactive({
      pageSize: 10,
    });

    const { result, loading, error, fetchMore } = useQuery(
      allRepositoriesQuery,
      {
        first: computed(() => parseInt(state.pageSize)),
        last: computed(() => parseInt(state.pageSize)),
        after: "",
        before: "",
      },
      { notifyOnNetworkStatusChange: true }
    );

    const repositories = useResult(result, [], (data) =>
      data.repositories.edges.map((edge) => edge.node)
    );

    const hasRepositories = computed(() => repositories.value.length > 0);

    const pageInfo = useResult(
      result,
      { hasPreviousPage: false, hasNextPage: false },
      (data) => data.repositories.pageInfo
    );

    const { hasPreviousPage, hasNextPage, loadMore } = useLoadMore(
      fetchMore,
      pageInfo
    );

    const handlePageSizeChange = (pageSize) => {
      if (pageSize) {
        state.pageSize = pageSize;
      }
    };

    const formatRepositories = computed(() => {
      let csvDataFormat =
        "data:text/csv;charset=utf-8,source;repository_name;url;auth_user;last_scanned";
      //TODO: this has to change to grab ALL repositories data not just the one from default item per page. can do this once has elasticsearch
      const repoRows = repositories.value.map((repo) => [
        repo.provider,
        repo.name,
        repo.url,
        repo.authUser,
        repo.lastScanned,
      ]);

      csvDataFormat = [
        csvDataFormat,
        ...repoRows.map((row) => row.join(";")),
      ].join("\n");
      return csvDataFormat;
    });

    return {
      loading,
      error,
      state,
      repositories,
      handlePageSizeChange,
      hasPreviousPage,
      hasNextPage,
      hasRepositories,
      loadMore,
      pageInfo,
      getUTCDateTime,
      csvExport,
      formatRepositories,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
  font-weight: normal;
  height: 40px;
}

@media (max-width: 1800px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
