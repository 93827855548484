<template>
  <div class="table-responsive">
    <table class="table table-sm small">
      <thead v-if="providersSummary.length > 0" class="table-head table-light">
        <tr>
          <th class="fit">PROVIDER ACCOUNT NAME</th>
          <th class="fit align-right"># OF ACCOUNTS</th>
        </tr>
      </thead>
      <tbody v-if="providersSummary.length > 0">
        <tr
          v-for="(data, index) in providersSummary"
          :key="index"
          class="button st-table-hover"
          role="button"
          @click="linkTo(data.provider)"
          @keypress="linkTo(data.provider)"
        >
          <td>{{ data.provider }}</td>
          <td class="align-right">{{ data.count }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>No providers</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "ProviderList",
  props: {
    providersSummary: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const linkTo = (providerName) => {
      router.push({
        name: "ProviderDetail",
        params: { providerName },
      });
    };

    return {
      linkTo,
    };
  },
};
</script>

<style lang="scss" scoped>
.table-head {
  font-size: 14px;
}

.align-right {
  padding-right: 30px;
  text-align: right;
}

@media (max-width: 1800px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
