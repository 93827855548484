<template>
  <div class="admin st-view-wrapper">
    <div class="st-view-header-wrapper">
      <div class="st-view-header-content">
        <div class="st-header-title">Admin Panel</div>
      </div>
    </div>
    <div class="st-view-body-wrapper">
      <div class="st-view-body-content">
        <div class="st-tab-selection">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                id="providerListView"
                class="st-tab-link"
                :class="{
                  active: !route.query.view || route.query.view === 'accounts',
                }"
                role="button"
                tabindex="0"
                @click="accountsView"
                @keypress="accountsView"
                >Accounts</a
              >
            </li>
            <li class="nav-item">
              <a
                id="repositoriesListView"
                class="st-tab-link"
                :class="{ active: route.query.view === 'repositories' }"
                role="button"
                tabindex="0"
                @click="reposView"
                @keypress="reposView"
                >Repositories</a
              >
            </li>
          </ul>
        </div>
        <div v-if="!route.query.view || route.query.view === 'accounts'">
          <div v-if="loading">Loading...</div>
          <div v-else-if="error">Error: {{ error.message }}</div>
          <div v-else-if="providersSummary">
            <ProviderList :providers-summary="providersSummary" />
          </div>
        </div>
        <div v-if="route.query.view === 'repositories'">
          <RepositoriesList />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProviderList from "../components/ProviderList.vue";
import RepositoriesList from "../components/RepositoriesList.vue";
import { useRoute, useRouter } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import allProvidersSummaryQuery from "@/graphql/allProvidersSummary.query.gql";
export default {
  name: "Admin",
  components: {
    RepositoriesList,
    ProviderList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const accountsView = () => {
      router.push({ query: { view: "accounts" } });
    };
    const reposView = () => {
      router.push({ query: { view: "repositories" } });
    };
    const { result, loading, error } = useQuery(allProvidersSummaryQuery);
    const providersSummary = useResult(
      result,
      [],
      (data) => data.accountSummary
    );

    return {
      loading,
      error,
      accountsView,
      reposView,
      route,
      providersSummary,
    };
  },
};
</script>

<style lang="scss" scoped>
.st-tab-selection {
  margin-bottom: 40px;
}
</style>
